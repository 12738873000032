body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.opaquetooltip {
  max-width: 250px;
}

::-webkit-scrollbar {
  width: 8px;
  position: absolute;
}
::-webkit-scrollbar-track {
  background: #dde2ff;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background: rgb(20, 0, 54);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9467f3;
}

.ReactCollapse--collapse {
  transition: height 250ms;
}

.dropdown-toggle::after {
display: none !important;
}
